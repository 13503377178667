import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';


import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { ListeRendezVousComponent } from './liste-rendez-vous/liste-rendez-vous.component';
import { AllRendezVousComponent } from './all-rendez-vous/all-rendez-vous.component';
import { LanceurOuvrirDossierComponent } from './lanceur-ouvrir-dossier/lanceur-ouvrir-dossier.component';
import { OuvrirDossierComponent } from './ouvrir-dossier/ouvrir-dossier.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { LanceurEnregistrerConsultationComponent } from './lanceur-enregistrer-consultation/lanceur-enregistrer-consultation.component';
import { EnregistrerConsultationComponent } from './enregistrer-consultation/enregistrer-consultation.component';
import { ListeConsultationsComponent } from './liste-consultations/liste-consultations.component';
import { ViewConsultationComponent } from './view-consultation/view-consultation.component';
import { ListeDossierOuvertComponent } from './liste-dossier-ouvert/liste-dossier-ouvert.component';
import { ViewDossierOuvertComponent } from './view-dossier-ouvert/view-dossier-ouvert.component';

import { LoginComponent } from './login/login.component';
import {
  AgmCoreModule
} from '@agm/core';
import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule
} from '@angular/material';






@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    DashboardComponent,
    UserProfileComponent,
    TableListComponent,
    TypographyComponent,
    IconsComponent,
    MapsComponent,
    NotificationsComponent,
    UpgradeComponent,
    ListeRendezVousComponent,
    AllRendezVousComponent,
    LanceurOuvrirDossierComponent,
    OuvrirDossierComponent,
    LanceurEnregistrerConsultationComponent,
    EnregistrerConsultationComponent,
    ListeConsultationsComponent,
    ViewConsultationComponent,
    ListeDossierOuvertComponent,
    ViewDossierOuvertComponent,
    LoginComponent,
    AppComponent,
  ],


  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
