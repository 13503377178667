import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {



  parametre: string = 'toto';
  items2: any[];
  data2: any;
  public url1:any;
  public login: string= '';
  public pass: string= '';
  public passok: boolean=false;
  public nom: string= '';

  public id_PATIENT: string= '';
  public nomU: string= '';
  public pnomU: string= '';
  //public lien: string= 'http://localhost/test/';
  public lien: string= 'http://api.destiny-innovation.com/v2/web/toolbox/';
  constructor(private http: Http) { }
    /***************Code login ******************* */
    loginfire(email: string, password: string) {
      return    this.http.post("http://api.destiny-innovation.com/v2/web/app_dev.php/loginrest", JSON.stringify({
           email: email,
           password: password
         }))
           .map((responseData) => responseData.json());
     }
     id_utilisateur(){

      return localStorage.getItem("id_utilisateur");
     }
     /***************Code inscription ******************* */
}