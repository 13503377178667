import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
//import { LocalDataSource } from 'ng2-smart-table';
import 'rxjs/add/operator/map';
import { Router,ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service/service.service';
@Component({
  selector: 'app-all-rendez-vous',
  templateUrl: './all-rendez-vous.component.html',
  styleUrls: ['./all-rendez-vous.component.scss']
})
export class AllRendezVousComponent implements OnInit {

 
  settings = {
    
    mode: 'inline',
    add: {
      addButtonContent: '<i class="fa fa-plus"></i>',
      createButtonContent: '<i class="fa fa-check"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmCreate: true,
    },
    
    edit: {
     

      editButtonContent: '<i class="fa fa-edit (alias)"></i>',
      saveButtonContent: '<i class="fa fa-check"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      //confirmEdit: true,
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="fa fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'number',
        editable:false,
      },
      Nom: {
        title: 'Nom(s)',
        type: 'string',
      },
      Prenom: {
        title: 'Prénom(s)',
        type: 'string',
      },
      Nom_utilisateur: {
        title: 'Nom d\'utilisateur',
        type: 'string',
      },
      Email: {
        title: 'E-mail',
        type: 'string',
      },
      Age: {
        title: 'Age',
        type: 'number',
      },
      motif: {
        title: 'Motif',
        type: 'string',
      },
      profession: {
        title: 'Profession',
        type: 'string',
      },
      date: {
        title: 'Date RD',
        type: 'number',
      },
      heur_debut: {
        title: 'Heure Début',
        type: 'number',
      },
      heur_fin: {
        title: 'Heure fin',
        type: 'number',
      },
    },
  };






  items1: any[];
  data2: any[];
  public url2:any;
  public url3:any;
  id_utilisateur1: String;
  id_utilisateur: any;
  constructor(private http: Http, private router: Router, private route1: ActivatedRoute,private param: ServiceService) {

  



   }

   recup() {
    this.url2=this.param.lien+'recup_rendez_vous.php?id_utilisateur='+this.id_utilisateur
    return this.http
    .get(this.url2)
    .map((data2: Response) =>data2.json())
  }

  ngOnInit() {
    this.id_utilisateur= this.param.id_utilisateur();
    this.recup().subscribe(
            data => {
           
              this.items1 = JSON.parse(JSON.stringify(data)); 
            },
              );
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce rendez-vous?')) {
      
      this.http.get(this.param.lien+'delete_rendez_vous.php?id='+event.data.id).subscribe(data => {
        console.log(data)
           });
           alert("Suppression Effectué");
           event.confirm.resolve();
    } else {
      event.confirm.reject();
    }

  }


  onSaveConfirm(event):void { 
    if (window.confirm('Êtes-vous sûr de vouloir Modifier ce rendez-vous?')) {
      this.http.get(this.param.lien+'updete_rendez_vous.php?Nom='+event.newData.Nom+'&Prenom='+event.newData.Prenom+'&Nom_utilisateur='
      +event.newData.Nom_utilisateur+'&Email='+event.newData.Email+'&Age='+event.newData.Age+'&date='+event.newData.date
      +'&heur_debut='+event.newData.heur_debut+'&heur_fin='+event.newData.heur_fin+'&id='+event.newData.id+'&Motif='+event.newData.Motif+'&Profession='+event.newData.Profession).subscribe(data => {
        console.log(data)
           }); 
           alert("Modification Effectué");
           event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
    } 

    onCreateConfirm(event):void { 
      if (window.confirm('Êtes-vous sûr de vouloir créer ce rendez-vous?')) { 
        this.http.get(this.param.lien+'add_rendez_vous.php?id='+event.newData.id+'&Nom='+event.newData.Nom+'&Prenom='+event.newData.Prenom+'&Nom_utilisateur='
        +event.newData.Nom_utilisateur+'&Email='+event.newData.Email+'&Age='+event.newData.Age+'&date='+event.newData.date
        +'&heur_debut='+event.newData.heur_debut+'&heur_fin='+event.newData.heur_fin
        +'&id_utilisateur='+this.id_utilisateur+'&Motif='+event.newData.Motif+'&Profession='+event.newData.Profession).subscribe(data => {
          console.log(data)
             }); 
             alert("Enregistrement Effectué");
             event.confirm.resolve();
      } else { 
       event.confirm.reject(); 
      } 
    }
    
    
}
