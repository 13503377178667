import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [

    { path: '/ListeRendezVous', title: 'Liste des rendez-vous',  icon:'receipt', class: '' },
    { path: '/AllRendezVous', title: 'Rendez-vous',  icon:'local_library', class: '' },
    { path: '/LanceurOuvrirDossier', title: 'Ouvrir un Dossier',  icon:'create_new_folder', class: '' },
    { path: '/LanceurEnregistrerConsultation', title: 'Eregistrer consultation',  icon:'archive', class: '' },
    { path: '/ListeConsultations', title: 'Liste des Consultations',  icon:'folder_shared', class: '' },
    { path: '/ListeDossierOuvert', title: 'Liste des Dossiers Ouvert',  icon:'folder_open', class: '' },
    
   /* { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },*/

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
