import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Http, Response } from '@angular/http';
import { ServiceService } from '../service/service.service';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
@Component({
  selector: 'app-view-dossier-ouvert',
  templateUrl: './view-dossier-ouvert.component.html',
  styleUrls: ['./view-dossier-ouvert.component.scss']
})
export class ViewDossierOuvertComponent implements OnInit {
  id_utilisateur: any;
  public items1: any[];
  data2: any[];
  public url2:any;
  names: string[] = [];
  source: LocalDataSource = new LocalDataSource();
  constructor(private http: Http, private router: Router,public param: ServiceService ) { 

    this.data2=[];
    this.recup().subscribe((dataGet) =>{
     this.items1=dataGet; 
 
   }
 )
  }

  ngOnInit() {

    this.id_utilisateur= this.param.id_utilisateur();
    
  }
  recup() {

    console.log("pppppppppppppppppppppppdddddp"+this.id_utilisateur);
   
    this.url2=this.param.lien+'recup_dossierOuvert.php?id_PATIENT='+this.param.id_PATIENT
    return this.http
    .get(this.url2)
    .map((data2: Response) =>data2.json())
  }
}
