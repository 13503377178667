import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';


//import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { ListeRendezVousComponent } from './liste-rendez-vous/liste-rendez-vous.component';
import { AllRendezVousComponent } from './all-rendez-vous/all-rendez-vous.component';
import { LanceurOuvrirDossierComponent } from './lanceur-ouvrir-dossier/lanceur-ouvrir-dossier.component';
import { OuvrirDossierComponent } from './ouvrir-dossier/ouvrir-dossier.component';
import { LanceurEnregistrerConsultationComponent } from './lanceur-enregistrer-consultation/lanceur-enregistrer-consultation.component';
import { EnregistrerConsultationComponent } from './enregistrer-consultation/enregistrer-consultation.component';
import { ListeConsultationsComponent } from './liste-consultations/liste-consultations.component';
import { ViewConsultationComponent } from './view-consultation/view-consultation.component';
import { ListeDossierOuvertComponent } from './liste-dossier-ouvert/liste-dossier-ouvert.component';
import { ViewDossierOuvertComponent } from './view-dossier-ouvert/view-dossier-ouvert.component';
import { LoginComponent } from './login/login.component';









const routes: Routes =[
  { path: 'dashboard',      component: DashboardComponent },
  { path: 'user-profile',   component: UserProfileComponent },
  { path: 'table-list',     component: TableListComponent },
  { path: 'typography',     component: TypographyComponent },
  { path: 'icons',          component: IconsComponent },
  { path: 'maps',           component: MapsComponent },
  { path: 'notifications',  component: NotificationsComponent },
  { path: 'upgrade',        component: UpgradeComponent },
  { path: 'ListeRendezVous',        component: ListeRendezVousComponent },
  { path: 'AllRendezVous',        component: AllRendezVousComponent },
  { path: 'LanceurOuvrirDossier',        component: LanceurOuvrirDossierComponent },
  { path: 'OuvrirDossier',        component: OuvrirDossierComponent },
  { path: 'LanceurEnregistrerConsultation',        component: LanceurEnregistrerConsultationComponent },
  { path: 'EnregistrerConsultation',        component: EnregistrerConsultationComponent },
  { path: 'ListeConsultations',        component: ListeConsultationsComponent },
  { path: 'ViewConsultation',        component: ViewConsultationComponent },
  { path: 'ListeDossierOuvert',        component: ListeDossierOuvertComponent  },
  { path: 'ViewDossierOuvert',        component: ViewDossierOuvertComponent },
  { path: 'login',      component: LoginComponent },
  { path: '',               redirectTo: 'ListeRendezVous', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
