import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Http, Response } from '@angular/http';
import { NgForm } from '@angular/forms';
import { ServiceService } from '../service/service.service';
@Component({
  selector: 'app-enregistrer-consultation',
  templateUrl: './enregistrer-consultation.component.html',
  styleUrls: ['./enregistrer-consultation.component.scss']
})
export class EnregistrerConsultationComponent implements OnInit {

  items2: any[];
  data2: any;
  public url1:any;
  sex:String;
  engagemrnt:String;
  condiTravai:String;
  daccou:String;
  radioSelect:String="";
  selectedEntry;
  id_utilisateur: any;
  constructor(private http: Http,private route: ActivatedRoute,private param: ServiceService) { 
  
   
  }

  ngOnInit() {
    this.id_utilisateur= this.param.id_utilisateur();
  }
 

  onFormSubmit(userForm: NgForm) {
 



     console.log("tt"+this.param.id_PATIENT);
     console.log("tt"+this.id_utilisateur);
     console.log("tt"+userForm.value.dateProch);
     console.log("tt"+userForm.value.Observation);
     console.log("tt"+userForm.value.objetC);
    this.http.get(this.param.lien+'add_consultation.php?objetC='+userForm.value.objetC+'&diagnoE='+userForm.value.diagnoE+'&Prescription='
    +userForm.value.Prescription+'&Recomm='+userForm.value.Recomm+'&Observation='+userForm.value.Observation+'&dateProch='+userForm.value.dateProch
    +'&idCo='+this.id_utilisateur+'&idpatient='+this.param.id_PATIENT).subscribe(data => {
      console.log(data)
         }); 

         
     }

}
