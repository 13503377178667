import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  items2: any[];
  data2: any;
  public url1:any;
  loading = false;
  erreur= false;
  message="";
constructor(private http: Http, private router: Router,public param: ServiceService) { 


    

//this.recuphere();


  }

  ngOnInit() {
  }

  recup() {
    this.url1=this.param.lien+'connexion.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }

  recuphere(){
    this.recup().subscribe((dataGet) =>{
      this.items2=dataGet;
    }
  )};





  onFormSubmit(userForm: NgForm) {
    this.loading = true;
    this.param.loginfire(userForm.value.email, userForm.value.password)
      .subscribe(data => {
    
        if (data) {
        this.erreur=!data.success;
        console.log(data);
        if(!data.success){
             this.message=data.message;
             this.loading = false;
        }else{

          this.param.login=data.user.username;
          this.param.nom=data.user.nomuser;
          this.param.pass='';
          console.log("data.user.id="+data.user.id);
          localStorage.setItem('id_utilisateur', data.user.id);
         // this.param.id_utilisateur()=data.user.id;
          this.param.passok=true;
          localStorage.setItem('LOGIN', data.user.username);
          localStorage.setItem('isconn', 'true');
          console.log("this.param.id_utilisateur()="+this.param.id_utilisateur());



          localStorage.setItem('token', data.user.token);
          localStorage.setItem('user', data.user);
          this.router.navigate(['ListeRendezVous']);
          this.loading = false;
        }
          
        }
    });

  }


}
