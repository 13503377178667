import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { ServiceService } from '../../service/service.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private username=  'yyyyyy' ;


            islogin:boolean;
    constructor(location: Location, private element: ElementRef, private router: Router,public param: ServiceService) {
        this.location = location;
        this.sidebarVisible = false;


        if(localStorage.getItem('isconn')=='true'){
            this.username=localStorage.getItem('LOGIN');
            this.islogin=true;
            console.log("fffffffffffffffffffffffffffffff");
        }else{
       
        }



    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(2);
        }
        titlee = titlee.split('/').pop();

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }
    Deconnexion() {
       // localStorage.removeItem('user');
        localStorage.removeItem('token');
     
       

        localStorage.removeItem('id_utilisateur');
        localStorage.removeItem('LOGIN');
        localStorage.removeItem('isconn');
        localStorage.removeItem('user');
        this.router.navigate(['login']);
    }
    logout() {
      
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.router.navigate(['dashboard']);
    };
}
