import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router ,ActivatedRoute} from '@angular/router';

import { ServiceService } from '../service/service.service';
@Component({
  selector: 'app-liste-rendez-vous',
  templateUrl: './liste-rendez-vous.component.html',
  styleUrls: ['./liste-rendez-vous.component.scss']
})
export class ListeRendezVousComponent implements OnInit {

 

  settings = {
    
    actions: false, 
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    
    edit: {
     

      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      //confirmEdit: true,
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'string',
        
      },
      Nom: {
        title: 'Nom(s)',
        type: 'string',
      },
      Prenom: {
        title: 'Prénom(s)',
        type: 'string',
      },
      Nom_utilisateur: {
        title: 'Nom utilisateur',
        type: 'string',
      },
      Email: {
        title: 'E-mail',
        type: 'string',
      },
      Age: {
        title: 'Age',
        type: 'string',
      },
      motif: {
        title: 'Motif',
        type: 'string',
      },
      profession: {
        title: 'Profession',
        type: 'string',
      },
      date: {
        title: 'Date RD',
        type: 'string',
      },
      heur_debut: {
        title: 'Heure Début',
        type: 'string',
      },
      heur_fin: {
        title: 'Heure fin',
        type: 'string',
      },
    },
  };

 
  items1=[];
  data2: any[];
  public url2:any;
  error:string;

  source: LocalDataSource = new LocalDataSource();
  id_utilisateur: any;

  constructor( private http: Http, private router: Router,  private route1: ActivatedRoute,private param: ServiceService) {
 




  }



  ngOnInit() {

    this.id_utilisateur= this.param.id_utilisateur();

    this.recup().subscribe(
      data => {
       // this.items1 = data; 
        this.items1 = JSON.parse(JSON.stringify(data)); 
      },
    error => this.error = error.message
        );
  }

  recup() {
    console.log(this.id_utilisateur);
    this.url2=this.param.lien+'recup_rendez_vous.php?id_utilisateur='+this.id_utilisateur
    return this.http
    .get(this.url2)
    .map((data2: Response) =>data2.json())
  }



}


