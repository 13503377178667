import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Http, Response } from '@angular/http';
import { ServiceService } from '../service/service.service';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
@Component({
  selector: 'app-lanceur-ouvrir-dossier',
  templateUrl: './lanceur-ouvrir-dossier.component.html',
  styleUrls: ['./lanceur-ouvrir-dossier.component.scss']
})
export class LanceurOuvrirDossierComponent implements OnInit {

  settings = {
    
    actions: false, 
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    
    edit: {
     

      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      //confirmEdit: true,
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'number',
      },
      Nom: {
        title: 'Nom(s)',
        type: 'string',
      },
      Prenom: {
        title: 'Prénom(s)',
        type: 'string',
      },
      Nom_utilisateur: {
        title: 'Nom d\'utilisateur',
        type: 'string',
      },
      Email: {
        title: 'E-mail',
        type: 'string',
      },
      Age: {
        title: 'Age',
        type: 'number',
      },
      motif: {
        title: 'Motif',
        type: 'string',
      },
      profession: {
        title: 'Profession',
        type: 'string',
      },
      date: {
        title: 'Date RD',
        type: 'number',
      },
      heur_debut: {
        title: 'Heure Début',
        type: 'number',
      },
      heur_fin: {
        title: 'Heure fin',
        type: 'number',
      },
    },
  };

  id_utilisateur: any;
 items1: any[];
  data2: any[];
  public url2:any;
  names: string[] = [];
  source: LocalDataSource = new LocalDataSource();


  constructor( private http: Http, private router: Router,public param: ServiceService) {
 




  }
  open3($event) {
    console.log($event.data.id);



    
    this.param.id_PATIENT=$event.data.id;
    this.param.nomU=$event.data.Nom;
    this.param.pnomU=$event.data.Prenom;



         this.router.navigate(['/OuvrirDossier']);

  }

  ngOnInit() {


    this.id_utilisateur= this.param.id_utilisateur();

    this.data2=[
  
    ];


    this.recup().subscribe(
            data => {
            
              this.items1 = JSON.parse(JSON.stringify(data)); 
            },
         // error => this.error = error.message
              );

  }

  recup() {

    console.log("pppppppppppppppppppppppdddddp"+this.id_utilisateur);
   
    this.url2=this.param.lien+'recup_rendez_vousOuvrirDossier.php?id_utilisateur='+this.id_utilisateur
    return this.http
    .get(this.url2)
    .map((data2: Response) =>data2.json())
  }



}
