import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Http, Response } from '@angular/http';
import { NgForm } from '@angular/forms';
import { ServiceService } from '../service/service.service';
@Component({
  selector: 'app-ouvrir-dossier',
  templateUrl: './ouvrir-dossier.component.html',
  styleUrls: ['./ouvrir-dossier.component.scss']
})
export class OuvrirDossierComponent implements OnInit {
  items2: any[];
  data2: any;
  public url1:any;
  sex:String;
  engagemrnt:String;
  condiTravai:String;
  daccou:String;
  radioSelect:String="";
  selectedEntry;
  public nom :String;
  public pnom :String;
      NOMPNOM:String;

  constructor(private http: Http,private route: ActivatedRoute,private param: ServiceService) { 
    this.nom=this.param.nomU;
    this.pnom=this.param.pnomU;
    this.NOMPNOM=this.param.nomU+" "+this.param.pnomU;
  }

  ngOnInit() {
  }
 

  onFormSubmit(userForm: NgForm) {
 

    this.http.get(this.param.lien+'add_dossier.php?nom='+this.NOMPNOM+'&dateLN='+userForm.value.dateLN+'&sexe='
    +userForm.value.Sexe+'&Profe='+userForm.value.Profe+'&Etabli='+userForm.value.Etabli+'&Adres='+userForm.value.Adres
    +'&nomPPere='+userForm.value.nomPPere+'&AdresP='+userForm.value.AdresP+'&nomPMere='+userForm.value.nomPMere+'&AdresM='+userForm.value.AdresM
    +'&AnteF='+userForm.value.AnteF+'&Mald='+userForm.value.Mald+'&PersC='+userForm.value.PersC+'&Motif='+userForm.value.Motif
    +'&attent='+userForm.value.attent+'&engagemt='+userForm.value.ouiNonE+'&autreInfo='+userForm.value.autreInfo+'&conditionsTravail='+userForm.value.ouiNonT
    +'&id='+this.param.id_PATIENT+'&StatutM='+userForm.value.StatutM+'&RegimeM='+userForm.value.RegimeM
    +'&RgF='+userForm.value.RgF+'&Tel='+userForm.value.Tel+'&Nivscolaire='+userForm.value.Nivscolaire).subscribe(data => {
      console.log(data)
         }); 

         alert("Enregistrement Effectué");
     }

}
